import { Col, Row } from "reactstrap"

const TutorialCheckout = () => {
    return (
        <Col className="labelText">

            <Row>
                <Col>
                    <h3>
                        Checkout
                    </h3>
                    <p>Review your advertising campaign budget and any download fees and quickly and securely make your payment.</p>
                    <p>Your advert will start to be broadcast on the date and time you specified and will run until the end date you set if your budget allows. You'll be notified if your advertising budget is running low and can always top it up if you wish in the Ads section of the site.</p>
                    <p>And you can also listen to and see stats for your adverts at all times in the Ads section. The adverts you want to download will also be available there.</p>
                </Col>
            </Row>

            <Row>
                <Col>
                    <h3>Downloading only</h3>
                    <p>
                        If you just want to download your advert, you just need to give it a name and click Next. Versions of your advert in different languages that you�ve created will have the same name and will be available to download from the Ads section of the site after you�ve been to the Checkout.
                    </p>
                </Col>
            </Row>



            <Row>
                <Col>
                    <h3>Questions? Email us at <a style={{ textDecoration: "underline", color: "", }} href="mailto:hello@klaxon.ai">hello@klaxon.ai</a></h3>
                </Col>
            </Row>


        </Col>
    )
}

export default TutorialCheckout